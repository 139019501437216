var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FullscreenModal",
    {
      attrs: { color: "appBarDark", dark: true },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("span", { staticClass: "notranslate" }, [
                _vm._v(_vm._s(_vm.channel ? _vm.channel.name : "")),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm.loading
        ? _c("LoadingText", { attrs: { absolute: "" } })
        : _vm.channel
        ? _c(
            "VCardText",
            [
              _c(
                "VLayout",
                { staticClass: "mb-3" },
                [
                  _vm.$vuetify.breakpoint.smAndUp ? _c("VSpacer") : _vm._e(),
                  _c(
                    "Menu",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "VBtn",
                                _vm._g(
                                  {
                                    attrs: {
                                      color: "primary",
                                      dark: "",
                                      block: _vm.$vuetify.breakpoint.xsOnly,
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$tr("downloadButton")) +
                                      "\n             \n            "
                                  ),
                                  _c("Icon", {
                                    attrs: {
                                      icon: "dropdown",
                                      color: _vm.$themeTokens.textInverted,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "VList",
                        [
                          _c(
                            "VListTile",
                            { on: { click: _vm.generatePdf } },
                            [
                              _c("VListTileTitle", [
                                _vm._v(_vm._s(_vm.$tr("downloadPDF"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "VListTile",
                            {
                              attrs: { "data-test": "dl-csv" },
                              on: {
                                click: function ($event) {
                                  return _vm.generateChannelsCSV([
                                    _vm.channelWithDetails,
                                  ])
                                },
                              },
                            },
                            [
                              _c("VListTileTitle", [
                                _vm._v(_vm._s(_vm.$tr("downloadCSV"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.channel && _vm.details
                ? _c("Details", {
                    staticClass: "channel-details-wrapper",
                    attrs: {
                      details: _vm.channelWithDetails,
                      loading: _vm.loading,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }